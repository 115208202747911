<template>
  <div class="downLoadAPP">
    
    
    <HFooterBtn :options='HFooterBtnOptions' @ok='handleEnter' />
  </div>
</template>

<script>
import { HFooterBtn } from "@/components/HVant";

import { appOptions } from "@/config/settingConfig/web.js";
export default {
  name: "downLoadAPP",
  components: {
    HFooterBtn,
  },
  data() {
    return {
      HFooterBtnOptions: {
        text: "进入APP",
      },
    };
  },

  methods: {
    handleEnter() {
      console.log("进入app");
    },
  },
};
</script>


